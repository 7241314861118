const BASE_URL = "https://api.goldensports.at";

const api = {
  request: {
    addRequest: `${BASE_URL}/request/addRequest`,
    addSendEmail: `${BASE_URL}/request/email_unsubscribe`,
  },
};
export default api;
export { BASE_URL };
