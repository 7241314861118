import { createRef, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import classes from "./header.module.css";

import LogoMenu from "./../../icons/menu.svg";
import LogoImage from "./../../pic/home/logo.svg";
import LogoText from "./../../pic/home/logo-text.svg";

const Header = ({ fixed = false }) => {
  const [locationMark, setLocationMark] = useState(0);
  const list_nav = createRef();
  const [actvieClass, setActiveClass] = useState(false);
  const [statusMenu, setStatusMenu] = useState(false);
  const [stickyHeader, setStickyHeader] = useState(false);

  const calculateofSize = (index) => {
    let number = 0;
    if (list_nav.current) {
      for (let i = 0; i < index; i++) {
        number += list_nav.current.children[i].clientWidth;
      }
    }
    return number;
  };

  const HandleMarkMovement = (index) => {
    if (list_nav.current) {
      if (index !== 1) {
        setLocationMark(calculateofSize(index) + (index - 1) * 80 - 17);
      } else {
        setLocationMark(0);
      }
    }
  };

  const openMenu = () => {
    setStatusMenu(true);
  };
  const closeMenu = () => {
    setStatusMenu(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 55) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    });
  }, []);

  return (
    <div
      className={`${classes.header_wrapper} ${fixed ? classes.fixed_class : ""} ${
        stickyHeader ? classes.sticky : ""
      }`}
    >
      <div
        className={`${classes.cover} ${statusMenu ? classes.active : ""}`}
        onClick={() => closeMenu()}
      ></div>
      <div className={classes.header}>
        <div className={classes.navbar}>
          <NavLink to="/" className={classes.logo}>
            <div className={classes.image}>
              <img src={LogoImage} />
            </div>
            <div className={classes.text}>
              <img src={LogoText} />
            </div>
          </NavLink>
          <div
            className={`${classes.list_nav} ${
              statusMenu ? classes.active : ""
            }`}
          >
            <div className={classes.nav_mobile_header}>
              <NavLink
                to="/"
                className={classes.logo}
                style={{ display: statusMenu ? "flex" : "none" }}
              >
                <div className={classes.image}>
                  <img src={LogoImage} />
                </div>
                <div className={classes.text}>
                  <img src={LogoText} />
                </div>
              </NavLink>
              {/* <div
                className={classes.button_close}
                onClick={() => closeMenu()}
              ></div> */}
            </div>
            <ul ref={list_nav}>
              <div
                className={`${classes.mark} ${
                  actvieClass ? classes.active : ""
                }`}
                style={{ left: `${locationMark}px` }}
              ></div>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => {
                    setActiveClass(true);
                    return isActive ? classes.active : "";
                  }}
                  onClick={() => {
                    HandleMarkMovement(1);
                    closeMenu();
                  }}
                >
                  <div className={classes.image}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.02 2.84004L3.63 7.04004C2.73 7.74004 2 9.23004 2 10.36V17.77C2 20.09 3.89 21.99 6.21 21.99H17.79C20.11 21.99 22 20.09 22 17.78V10.5C22 9.29004 21.19 7.74004 20.2 7.05004L14.02 2.72004C12.62 1.74004 10.37 1.79004 9.02 2.84004Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 17.99V14.99"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p>Home</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/events"
                  className={({ isActive }) => {
                    setActiveClass(true);
                    return isActive ? classes.active : "";
                  }}
                  onClick={() => {
                    HandleMarkMovement(2);
                    closeMenu();
                  }}
                >
                  <div className={classes.image}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1504 16.5V18.6"
                        stroke="#FFCB00"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.15039 22H17.1504V21C17.1504 19.9 16.2504 19 15.1504 19H9.15039C8.05039 19 7.15039 19.9 7.15039 21V22V22Z"
                        stroke="#FFCB00"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                      />
                      <path
                        d="M6.15039 22H18.1504"
                        stroke="#FFCB00"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 16C8.13 16 5 12.87 5 9V6C5 3.79 6.79 2 9 2H15C17.21 2 19 3.79 19 6V9C19 12.87 15.87 16 12 16Z"
                        stroke="#FFCB00"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.47004 11.65C4.72004 11.41 4.06004 10.97 3.54004 10.45C2.64004 9.44998 2.04004 8.24998 2.04004 6.84998C2.04004 5.44998 3.14004 4.34998 4.54004 4.34998H5.19004C4.99004 4.80998 4.89004 5.31998 4.89004 5.84998V8.84998C4.89004 9.84998 5.10004 10.79 5.47004 11.65Z"
                        stroke="#FFCB00"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.5303 11.65C19.2803 11.41 19.9403 10.97 20.4603 10.45C21.3603 9.44998 21.9603 8.24998 21.9603 6.84998C21.9603 5.44998 20.8603 4.34998 19.4603 4.34998H18.8103C19.0103 4.80998 19.1103 5.31998 19.1103 5.84998V8.84998C19.1103 9.84998 18.9003 10.79 18.5303 11.65Z"
                        stroke="#FFCB00"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p>Events</p>
                </NavLink>
              </li>
              <li>
                <Link
                  to="https://goldenshop.at/"
                  className={({ isActive }) => {
                    setActiveClass(true);
                    return isActive ? classes.active : "";
                  }}
                  onClick={() => {
                    HandleMarkMovement(3);
                    closeMenu();
                  }}
                >
                  <div className={classes.image}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.81043 2L5.19043 5.63"
                        stroke="white"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.1904 2L18.8104 5.63"
                        stroke="white"
                        strokeWidth="1.5"
                        stroke-miterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 7.84998C2 5.99998 2.99 5.84998 4.22 5.84998H19.78C21.01 5.84998 22 5.99998 22 7.84998C22 9.99998 21.01 9.84998 19.78 9.84998H4.22C2.99 9.84998 2 9.99998 2 7.84998Z"
                        stroke="white"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M9.75977 14V17.55"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M14.3604 14V17.55"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M3.5 10L4.91 18.64C5.23 20.58 6 22 8.86 22H14.89C18 22 18.46 20.64 18.82 18.76L20.5 10"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <p>Shop</p>
                </Link>
              </li>
              <li>
                <NavLink
                  to="/products"
                  className={({ isActive }) => {
                    setActiveClass(true);
                    return isActive ? classes.active : "";
                  }}
                  onClick={() => {
                    HandleMarkMovement(4);
                    closeMenu();
                  }}
                >
                  <div className={classes.image}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.44 2H17.55C21.11 2 22 2.89 22 6.44V12.77C22 16.33 21.11 17.21 17.56 17.21H6.44C2.89 17.22 2 16.33 2 12.78V6.44C2 2.89 2.89 2 6.44 2Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 17.22V22"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 13H22"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 22H16.5"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p>Products</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/team"
                  className={({ isActive }) => {
                    setActiveClass(true);
                    return isActive ? classes.active : "";
                  }}
                  onClick={() => {
                    HandleMarkMovement(5);
                    closeMenu();
                  }}
                >
                  <div className={classes.image}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.15957 10.87C9.05957 10.86 8.93957 10.86 8.82957 10.87C6.44957 10.79 4.55957 8.84 4.55957 6.44C4.55957 3.99 6.53957 2 8.99957 2C11.4496 2 13.4396 3.99 13.4396 6.44C13.4296 8.84 11.5396 10.79 9.15957 10.87Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.4103 4C18.3503 4 19.9103 5.57 19.9103 7.5C19.9103 9.39 18.4103 10.93 16.5403 11C16.4603 10.99 16.3703 10.99 16.2803 11"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.15973 14.56C1.73973 16.18 1.73973 18.82 4.15973 20.43C6.90973 22.27 11.4197 22.27 14.1697 20.43C16.5897 18.81 16.5897 16.17 14.1697 14.56C11.4297 12.73 6.91973 12.73 4.15973 14.56Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.3398 20C19.0598 19.85 19.7398 19.56 20.2998 19.13C21.8598 17.96 21.8598 16.03 20.2998 14.86C19.7498 14.44 19.0798 14.16 18.3698 14"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p>Team</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/impressum"
                  className={({ isActive }) => {
                    setActiveClass(true);

                    return isActive ? classes.active : "";
                  }}
                  onClick={() => {
                    HandleMarkMovement(6);
                    closeMenu();
                  }}
                >
                  <div className={classes.image}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 8.25V18C20 21 18.21 22 16 22H8C5.79 22 4 21 4 18V8.25C4 5 5.79 4.25 8 4.25C8 4.87 8.24997 5.43 8.65997 5.84C9.06997 6.25 9.63 6.5 10.25 6.5H13.75C14.99 6.5 16 5.49 16 4.25C18.21 4.25 20 5 20 8.25Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 4.25C16 5.49 14.99 6.5 13.75 6.5H10.25C9.63 6.5 9.06997 6.25 8.65997 5.84C8.24997 5.43 8 4.87 8 4.25C8 3.01 9.01 2 10.25 2H13.75C14.37 2 14.93 2.25 15.34 2.66C15.75 3.07 16 3.63 16 4.25Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 13H12"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 17H16"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p>Impressum</p>
                </NavLink>
              </li>
            </ul>
            <div className={classes.button}>
              <HashLink to="/#contactus" smooth onClick={() => closeMenu()}>
                <p>Kontakt Jetzt</p>
              </HashLink>
            </div>
          </div>
          <div className={classes.button_open_menu} onClick={() => openMenu()}>
            <div className={classes.image}>
              <img src={LogoMenu} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
