import classes from "./footer.module.css";

import LogoImage from "./../../pic/home/logo.svg";
import LogoText from "./../../pic/home/logo-text.svg";
import InstagramIcon from "./../../icons/instagram.svg";
import FaceBookIcon from "./../../icons/facebook.svg";
import TwitterIcon from "./../../icons/twiiter.svg";
import LinkedinIcon from "./../../icons/linkedin.svg";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className={classes.footer_wrapper}>
      <div className={classes.footer}>
        <div className={classes.items_left}>
          <div className={classes.logo}>
            <div className={classes.image}>
              <img src={LogoImage} />
            </div>
            <div className={classes.text}>
              <img src={LogoText} />
            </div>
          </div>
          <div className={classes.explain}>
            <p>From Grassroots to Glorey Get All You Need</p>
          </div>
          <div className={classes.link}>
              <NavLink to="/events-agb" target="_blank">
                Events AGB
              </NavLink>
          </div>
        </div>
        <div className={classes.items_right}>
          <div className={classes.social_media}>
            <div className={classes.title}>
              <p>Follow us in social media</p>
            </div>
            <div className={classes.items}>
              <div className={classes.item}>
                <img src={InstagramIcon} />
              </div>
              <div className={classes.item}>
                <img src={FaceBookIcon} />
              </div>
              <div className={classes.item}>
                <img src={TwitterIcon} />
              </div>
              <a href="https://www.linkedin.com/company/goldensportsat" target="_blank" className={classes.item}>
                <img src={LinkedinIcon} />
              </a>
            </div>
          </div>
          <div className={classes.footer_explain}>
            <p>
              Developed by Goldensports tech team, <a href="https://eramond.com/">Eramond</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
