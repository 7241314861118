import classes from "./popup.module.css";

import SuccessIcon from "./../../icons/tick.svg";
import ErrorIcon from "./../../icons/close.svg";
import WarnningIcon from "./../../icons/danger.svg";
import { NavLink } from "react-router-dom";

const PopupOkfb = ({ showPopup, result_req, message, changeShowPopupStatus }) => {
  return (
    <div className={`${classes.cover} ${showPopup ? classes.active : ""}`}>
      <div className={classes.popup_wrapper}>
        <div className={classes.popup}></div>
        <div className={classes.header}>
          <div className={classes.image}>
            <img
              src={
                result_req === 1
                  ? SuccessIcon
                  : result_req === 0
                  ? SuccessIcon
                  : ErrorIcon
              }
            />
          </div>
          <div className={classes.text}>
            <p
              style={{
                color:
                  result_req === 1
                    ? "#39EC6B"
                    : result_req === 0
                    ? "#39EC6B"
                    : "#39EC6B",
              }}
            >
              {result_req === 1
                ? "Registrierung erfolgreich"
                : "Registrierung erfolgreich"}
            </p>
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.text}>
            <p>{message}</p>
          </div>
          <NavLink
            to="https://oekfb.com"
            onClick={() => {
              changeShowPopupStatus();
            }}
            className={classes.button}
          >
            Yesü
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PopupOkfb;
