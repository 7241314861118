import { useSendRegisterCode } from "Hooks/Request/useSendEmail";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import classes from "./unsubscribe.module.css";

export const Unsubscribe = () => {
  const { sendRegisterCode, isLoading, result } = useSendRegisterCode();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  useEffect(() => {
    sendRegisterCode(email);
  }, []);

  if (isLoading) {
    return <div className={classes.container}>Loading...</div>;
  }

  return (
    <div className={classes.container}>
      <span>You Have Successfully Unsubscribed.</span>
    </div>
  );
};
