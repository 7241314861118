// import Layout from "./Components/Layout"
import { Routes, Route, useNavigate } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import Layout from "./Components/Layout";
import CommingSoon from "./pages/comming-soon/commingSoon";
import { Unsubscribe } from "pages/unsubscribe/unsubscribe";
import { OKFBanmeldung } from "pages/OKFBanmeldung/OKFBanmeldung";

const Router = () => {
  const Home = lazy(() => import("./pages/Home/Home"));
  // const Events = lazy(() => import("./pages/Events"));
  // const Emanager = lazy(() => import("./pages/eManager"));
  const EventAGB = lazy(() => import("./pages/event-agb/eventAGB"));
  const Impressum = lazy(() => import("./pages/Impressum/Impressum"));
  // const Partners = lazy(() => import("./pages/Partners"));
  // const SummerCup = lazy(() => import("./pages/SummerCup/home/home"));
  // const Register = lazy(() => import("./pages/SummerCup/register/register"));
  // const LayoutSommerCup = lazy(() =>
  //   import("./Components/layout-summercup/layoutSommerCup")
  // );
  // const LayoutSommerCupRegister = lazy(() =>
  //   import("./Components/layout-summercup-register/layoutSommerCupRegister")
  // );
  // const EventsLayout = lazy(() =>
  //   import("./Components/events-layout/eventsLayout")
  // );
  const EventsPage = lazy(() => import("./pages/Events/Events"));
  const ProductsPage = lazy(() => import("./pages/products/products"));
  const TeamsPage = lazy(() => import("./pages/teams/teams"));

  const Redirect = () => {
    const navigate = useNavigate();
    useEffect(() => {
      window.location.href = "https://sommercup.goldensports.at/";
    }, []);
    return <>Please wait ...</>;
  };

  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/coming-soon" element={<CommingSoon />} />
          <Route path="/team" element={<TeamsPage />} />
          <Route path="/Impressum" element={<Impressum />} />
          <Route path="/events-agb" element={<EventAGB />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path='/unsubscribe' element={<Unsubscribe />} />
          <Route path='/OKFBanmeldung' element={<OKFBanmeldung />} />
        </Route>
        <Route path="/sommerCup" element={<Redirect />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
