import { Helmet } from "react-helmet";
import classes from "./OKFBanmeldung.module.css";
import useRegister from "Hooks/Request/useRegister";
import { useForm } from "react-hook-form";
import {  useState } from "react";
import { NavLink } from "react-router-dom";
import Loading from "Components/Loading/loading";
import FileInput from "Components/fileInput/FileInput";
import plusbox from "./../../icons/plus-box.svg";
import OKFBLogo from "./../../pic/register-okfb/logo-explain.png";
import PopupOkfb from "Components/popup-okfb/popup";

export const OKFBanmeldung = () => {
  const email_regular =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [firstNameStatus, setFirstNameStatus] = useState(false);
  const [lastNameStatus, setLastNameStatus] = useState(false);
  const [phoneNumberStatus, setPhoneNumberStatus] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [addressStatus, setAddressStatus] = useState(false);
  const [teamNameStatus, setTeamNameStatus] = useState(false);
  const [zipCodeStatus, setZipCodeStatus] = useState(false);
  const [cityStatus, setCityStatus] = useState(false);

  const [dataSelect, setDataSelect] = useState([]);

  const {
    handle_register,
    sending,
    result_req,
    showPopup,
    message,
    changeShowPopupStatus,
  } = useRegister();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const handleRegister = (data) => {
    let data_req = {
      f_name: data.firstName,
      l_name: data.lastName,
      mobile: data.phoneNumber,
      email: data.email,
      // address: data.address,
      // zip_code: data.zipCode,
      city: data.city,
      team_name: data.teamName,
      file: data.file,
    };
    console.log(data_req);
    handle_register(data_req);
  };

  const fileInputHandler = (e) => {
    if (e.target.files) setValue("file", e.target.files[0]);
  };

  return (
    <>
      <Helmet>
        <title>ÖKFB Kleinfeld Liga</title>
      </Helmet>
      <PopupOkfb
        showPopup={showPopup}
        result_req={result_req}
        message={message}
        changeShowPopupStatus={changeShowPopupStatus}
      />
      <div className={classes.register_layout}>
        <div className={classes.register_wrapper}>
          <div className={classes.register}>
            <div className={classes.container}>
              <div className={classes.items_left}>
                <div className={classes.image}>
                  <img src={OKFBLogo} />
                </div>
                <div className={classes.register_wrapper}>
                  <div className={classes.title}>
                    <p>ÖKFB Kleinfeld Liga</p>
                  </div>

                  <form onSubmit={handleSubmit(handleRegister)}>
                    <div className={classes.input_layout}>
                      <div className={classes.input_wrapper}>
                        <label>
                          <span>Vorname</span>
                          <div
                            className={`${classes.input} ${
                              errors.firstName ? classes.error : ""
                            }
                        ${firstNameStatus ? classes.active : ""}
                        `}
                          >
                            <input
                              type="text"
                              {...register("firstName", {
                                required: "Bitte füllen Sie dieses Feld aus.",
                              })}
                              onFocus={() => {
                                setFirstNameStatus(true);
                              }}
                              onBlur={() => {
                                setFirstNameStatus(false);
                              }}
                            />
                          </div>
                          {errors.firstName && (
                            <span className={classes.error}>
                              {errors.firstName.message}
                            </span>
                          )}
                        </label>
                      </div>
                      <div className={classes.input_wrapper}>
                        <label>
                          <span>Nachname</span>
                          <div
                            className={`${classes.input} ${
                              errors.lastName ? classes.error : ""
                            }
                        ${lastNameStatus ? classes.active : ""}
                        `}
                          >
                            <input
                              type="text"
                              {...register("lastName", {
                                required: "Bitte füllen Sie dieses Feld aus.",
                              })}
                              onFocus={() => {
                                setLastNameStatus(true);
                              }}
                              onBlur={() => {
                                setLastNameStatus(false);
                              }}
                            />
                          </div>
                          {errors.lastName && (
                            <span className={classes.error}>
                              {errors.lastName.message}
                            </span>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className={classes.input_layout}>
                      <div className={classes.input_wrapper}>
                        <label>
                          <span>Telefonnummer</span>
                          <div
                            className={`${classes.input} ${
                              errors.phoneNumber ? classes.error : ""
                            }
                        ${phoneNumberStatus ? classes.active : ""}
                        `}
                          >
                            <input
                              type="text"
                              {...register("phoneNumber", {
                                required: "Bitte füllen Sie dieses Feld aus.",
                              })}
                              onFocus={() => {
                                setPhoneNumberStatus(true);
                              }}
                              onBlur={() => {
                                setPhoneNumberStatus(false);
                              }}
                            />
                          </div>
                          {errors.phoneNumber && (
                            <span className={classes.error}>
                              {errors.phoneNumber.message}
                            </span>
                          )}
                        </label>
                      </div>
                      <div className={classes.input_wrapper}>
                        <label>
                          <span>E-Mail-Adresse</span>
                          <div
                            className={`
                        ${classes.input} 
                        ${errors.email ? classes.error : ""}
                        ${emailStatus ? classes.active : ""}
                        `}
                          >
                            <input
                              type="text"
                              {...register("email", {
                                pattern: {
                                  message: "Ihr E-Mail-Format ist ungültig.",
                                  value: email_regular,
                                },
                                required: "Bitte füllen Sie dieses Feld aus.",
                              })}
                              onFocus={() => {
                                setEmailStatus(true);
                              }}
                              onBlur={() => {
                                setEmailStatus(false);
                              }}
                            />
                          </div>
                          {errors.email && (
                            <span className={classes.error}>
                              {errors.email.message}
                            </span>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className={classes.input_layout}>
                    
                    </div>
                    <div className={classes.input_layout}>
                      <div className={classes.input_wrapper}>
                        <label>
                          <span>Bundesland</span>
                          <div
                            className={`
                        ${classes.input} 
                        ${errors.address ? classes.error : ""}
                        ${addressStatus ? classes.active : ""}
                        `}
                          >
                            <input
                              type="text"
                              {...register("city", {
                                required: "Bitte füllen Sie dieses Feld aus.",
                              })}
                              onFocus={() => {
                                setAddressStatus(true);
                              }}
                              onBlur={() => {
                                setAddressStatus(false);
                              }}
                            />
                          </div>
                          {errors.address && (
                            <span className={classes.error}>
                              {errors.address.message}
                            </span>
                          )}
                        </label>
                      </div>
                      <div className={classes.input_wrapper}>
                        <label>
                          <span>Teamname</span>
                          <div
                            className={`
                        ${classes.input} 
                        ${errors.teamName ? classes.error : ""}
                        ${teamNameStatus ? classes.active : ""}
                        `}
                          >
                            <input
                              type="text"
                              {...register("teamName", {
                                required: "Bitte füllen Sie dieses Feld aus.",
                              })}
                              onFocus={() => {
                                setTeamNameStatus(true);
                              }}
                              onBlur={() => {
                                setTeamNameStatus(false);
                              }}
                            />
                          </div>
                          {errors.teamName && (
                            <span className={classes.error}>
                              {errors.teamName.message}
                            </span>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className={classes.importFileContainer}>
                      <FileInput
                        id="uploadFile"
                        image={plusbox}
                        maxSize={2000000}
                        {...register("file", {
                          required: "Bitte füllen Sie dieses Feld aus.",
                        })}
                        onChange={fileInputHandler}
                      />
                      {errors.file && (
                        <span className={classes.error}>
                          {errors.file.message}
                        </span>
                      )}
                    </div>
                    <div className={classes.checkbox_layout}>
                      <div className={classes.checkbox_wrapper}>
                        <input
                          type="checkbox"
                          {...register("acceptAGB", {
                            validate: (value) =>
                              value === false
                                ? "Bitte füllen Sie dieses Feld aus."
                                : undefined,
                          })}
                        />
                        <div className={classes.checkbox}></div>
                        <div className={classes.text}>
                          <p>
                            Ich interessiere mich für die Teilnahme und melde
                            mich hiermit unverbindlich an.
                          </p>
                        </div>
                      </div>
                      {errors.acceptAGB && (
                        <span className={classes.error}>
                          {errors.acceptAGB.message}
                        </span>
                      )}
                    </div>
                    <div className={classes.button_show_register}>
                      <NavLink
                        to="https://oekfb.com/index.php?action=faq1"
                        className={classes.button_back}
                      >
                        <div className={classes.image}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.57 5.93L3.5 12L9.57 18.07"
                              stroke="#FFEA00"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.4999 12H3.66992"
                              stroke="#FFEA00"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </NavLink>
                      <button
                        type="submit"
                        className={classes.button}
                        disabled={sending ? true : false}
                      >
                        <p>{sending ? "sending" : "Register Now"}</p>
                        {sending ? <Loading /> : null}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
