import { useState } from "react";
import axios from "axios";
import api from "../../Constants/API";

export const useSendRegisterCode = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState({ data: {}, result: false });

  const sendRegisterCode = async (email) => {
    const formData = new FormData();
    formData.append("email", email);

    try {
      setIsLoading(true);
      await axios
        .post(api.request.addSendEmail, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          setIsLoading(false);
          setResult(data);
        });
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  };

  return {
    sendRegisterCode,
    result,
    isLoading,
    error,
  };
};