import Loading from "../../Components/Loading/loading";
import classes from "./commingSoon.module.css";

const CommingSoon = () => {
  return (
    <div className={classes.comming_soon_wrapper}>
      <div className={classes.comming_soon}>
        <div className={classes.title}>
          <h1>Coming Soon</h1>
        </div>
      </div>
    </div>
  );
};

export default CommingSoon;
