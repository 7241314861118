import axios from "axios";
import { useState } from "react";

const useRegister = () => {
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const [result_req, setResult_req] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const changeShowPopupStatus = () => {
    setShowPopup(false);
  };

  const handle_register = async ({
    f_name,
    l_name,
    mobile,
    email,
    address,
    zip_code,
    city,
    team_name,
    file
  }) => {
    const formData = new FormData();
    formData.append("f_name", f_name);
    formData.append("l_name", l_name);
    formData.append("mobile", mobile);
    // formData.append("address", address);
    formData.append("email", email);
    // formData.append("zip_code", zip_code);
    formData.append("city", city);
    formData.append("team_name", team_name);
    formData.append("file", file);

    try {
      setSending(true);
      await axios
        .post(
          "https://api.goldensports.at/request/addRequest_okfb",
          formData,
          {
            headers: {
              Accept: "application/json, text/plain, */*",
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (res.data.data.email_error_msg !== "") {
              setSending(false);
              if (res.data.data) {
                setMessage(res.data.data.message);
                setResult_req(res.data.data.email_sended ? 1 : 0);
                setShowPopup(true);
              }
            } else {
              setSending(false);
              if (res.data.data) {
                setMessage(res.data.data.message);
                setResult_req(res.data.data.email_sended ? 1 : 0);
                setShowPopup(true);
              }
            }
          }
        })
        .catch((e) => {
          setSending(false);
          setMessage(e);
          setResult_req(2);
          setShowPopup(true);
        });
    } catch (err) {
      setSending(false);
      setMessage(err.message);
      setResult_req(2);
      setShowPopup(true);
    }
  };

  return {
    handle_register,
    changeShowPopupStatus,
    sending,
    message,
    result_req,
    showPopup,
  };
};

export default useRegister;
