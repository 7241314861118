import { Outlet, useLoaderData, useLocation } from "react-router-dom";
import Footer from "./footer/footer";
import Header from "./header/header";

import classes from "./layout.module.css";

const Layout = ({ children, footer = true }) => {
  let path = useLocation().pathname.toLowerCase();

  const list_none_footer = ["/sommercup/register", "/events", "/products"];
  const fixed_header = ["/events", "/products"];
  
  return (
    <div
      className={`${classes.landing_page_wrapper}`}
    >
      <div
        className={`mx-auto w-full overflow-clip flex flex-col min-h-screen relative ${classes.layout}`}
      >
        <Header fixed={fixed_header.includes(path) ? true : false} />
        <Outlet />
        {!list_none_footer.includes(path) ? <Footer /> : null}
      </div>
    </div>
  );
};

export default Layout;
